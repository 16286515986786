import { MAINTAIN_PERMISSION, GROWERS_INTAKELIST } from "@kraftheinz/common";
import { g as INTAKE_STATUS_CANCELLED, b as INTAKE_STATUS_INCOMPLETE, h as INTAKE_STATUS_REJECTED, e as INTAKE_STATUS_AVAILABLE_FOR_UPLOAD, c as INTAKE_STATUS_ENTERED, d as INTAKE_STATUS_SAMPLE_RECORDED, I as INTAKE_STATUS_COLORS, a as INTAKE_STATUS_TEXTS } from "./intake.status.js";
import { T as TRUCK_TYPE_INTAKE } from "./truck.types.js";
import { G as GondolaLogAndGrower, I as IntakeCode, a as IntakeInformation, N as NetProduceWeight, W as WeightInBinsComment, b as WeightOut } from "./index54.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-modal", {
          attrs: {
            "destroy-on-close": "",
            "title": "Cancel",
            "visible": _vm.visible
          },
          on: {
            "cancel": _vm.cancel
          },
          scopedSlots: _vm._u([{
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                on: {
                  "click": _vm.cancel
                }
              }, [_vm._v("Cancel")]), _c("a-button", {
                attrs: {
                  "loading": _vm.isLoading,
                  "type": "primary"
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_vm._v("OK")])];
            },
            proxy: true
          }], null, true)
        }, [_c("a-row", {
          attrs: {
            "align": "middle",
            "gutter": 24,
            "type": "flex"
          }
        }, [_c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("text-area", {
          attrs: {
            "form-item": "",
            "label": "Reason",
            "max-length": 50,
            "rules": "required",
            "value": _vm.reason
          },
          on: {
            "change": function($event) {
              _vm.reason = $event;
            }
          }
        })], 1)], 1)], 1)];
      }
    }])
  });
};
var staticRenderFns$3 = [];
const __vue2_script$3 = {
  name: "CancelIntake",
  inject: ["resourceProps"],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const [intakeProps] = this.resourceProps;
    return {
      intakeProps,
      isLoading: false,
      reason: ""
    };
  },
  computed: {
    intake() {
      return this.intakeProps.crud.getEntity() || {};
    }
  },
  methods: {
    cancel() {
      this.$emit("update:visible", false);
      this.reason = "";
    },
    async submit() {
      this.isLoading = true;
      try {
        const { apiUrl: apiUrl2 } = this.intakeProps;
        const { id } = this.intake;
        const { data } = await this.axios.put(`${apiUrl2}/grower/intakes/${id}/cancel`, {
          reason: this.reason
        });
        this.intakeProps.crud.setEntity(data);
        this.$notification.success({ message: "Item Updated" });
        this.$emit("update:visible", false);
      } catch (err) {
        let errMess = this._.get(err, "response.data.message", "Something went wrong! Please try again later.");
        this.$notification.error({
          message: errMess
        });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, null, null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var CancelIntake = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-modal", {
          attrs: {
            "destroy-on-close": "",
            "title": "Reject",
            "visible": _vm.visible
          },
          on: {
            "cancel": _vm.cancel
          },
          scopedSlots: _vm._u([{
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                on: {
                  "click": _vm.cancel
                }
              }, [_vm._v("Cancel")]), _c("a-button", {
                attrs: {
                  "loading": _vm.isLoading,
                  "type": "primary"
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_vm._v("OK")])];
            },
            proxy: true
          }], null, true)
        }, [_c("a-row", {
          attrs: {
            "align": "middle",
            "gutter": 24,
            "type": "flex"
          }
        }, [_c("a-col", {
          attrs: {
            "span": 16
          }
        }, [_c("text-area", {
          attrs: {
            "form-item": "",
            "label": "Reason",
            "max-length": 50,
            "rules": "required",
            "value": _vm.reason
          },
          on: {
            "change": function($event) {
              _vm.reason = $event;
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 8
          }
        }, [_c("checkbox-input", {
          attrs: {
            "form-item": "",
            "label": "Produce returned",
            "value": _vm.produceReturned
          },
          on: {
            "change": function($event) {
              _vm.produceReturned = $event;
            }
          }
        })], 1)], 1)], 1)];
      }
    }])
  });
};
var staticRenderFns$2 = [];
const __vue2_script$2 = {
  name: "RejectIntake",
  inject: ["resourceProps"],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const [intakeProps] = this.resourceProps;
    return {
      intakeProps,
      isLoading: false,
      produceReturned: false,
      reason: ""
    };
  },
  computed: {
    intake() {
      return this.intakeProps.crud.getEntity() || {};
    }
  },
  methods: {
    cancel() {
      this.$emit("update:visible", false);
      this.reason = "";
      this.produceReturned = false;
    },
    async submit() {
      this.isLoading = true;
      try {
        const { apiUrl: apiUrl2 } = this.intakeProps;
        const { id } = this.intake;
        const { data } = await this.axios.put(`${apiUrl2}/grower/intakes/${id}/reject`, {
          reason: this.reason,
          produceReturned: this.produceReturned
        });
        this.intakeProps.crud.setEntity(data);
        this.$notification.success({ message: "Item Updated" });
        this.$emit("update:visible", false);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var RejectIntake = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "create-edit-intake"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.menuItems
    }
  }), _c("a-row", {
    staticClass: "header pt-1 px-5 pb-3",
    attrs: {
      "align": "middle",
      "gutter": 16,
      "type": "flex"
    }
  }, [_c("a-col", [_c("a-icon", {
    staticClass: "h5",
    attrs: {
      "type": "arrow-left"
    },
    on: {
      "click": _vm.onBackClick
    }
  })], 1), _c("a-col", [_vm.create ? _c("div", {
    staticClass: "h5"
  }, [_vm._v("New Intake")]) : _c("div", {
    staticClass: "h5"
  }, [_vm._v("Intake - " + _vm._s(_vm.intake.intakeNumber))])]), !_vm.create ? _c("a-col", [_c("tag-field", {
    attrs: {
      "color": _vm.statusColor,
      "value": _vm.statusText
    }
  })], 1) : _vm._e()], 1), _c("a-row", {
    staticClass: "p-5"
  }, [_c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-row", {
          attrs: {
            "gutter": 24,
            "type": "flex"
          }
        }, [_c("a-col", {
          staticClass: "mb-4",
          attrs: {
            "span": 8
          }
        }, [_c("intake-code", {
          attrs: {
            "create": _vm.create,
            "disabled": !_vm.hasMaintainPermission
          }
        })], 1), _c("a-col", {
          staticClass: "mb-4",
          attrs: {
            "span": 16
          }
        }, [_c("intake-information", {
          attrs: {
            "disabled": !_vm.hasMaintainPermission,
            "is-inited": _vm.isInited
          },
          on: {
            "change:crop": function($event) {
              _vm.selectedCrop = $event;
            },
            "change:truck": function($event) {
              _vm.selectedTruck = $event;
            }
          }
        })], 1), _c("a-col", {
          staticClass: "mb-4",
          attrs: {
            "span": 24
          }
        }, [_c("gondola-log-and-grower", {
          attrs: {
            "crop": _vm.selectedCrop,
            "is-submitting": _vm.isSubmitting,
            "truck-id": _vm.selectedTruck.id,
            "disabled": !_vm.hasMaintainPermission
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 16
          }
        }, [_c("weight-in-bins-comment", {
          attrs: {
            "crop": _vm.selectedCrop,
            "disabled": !_vm.hasMaintainPermission,
            "is-inited": _vm.isInited
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 8
          }
        }, [_c("weight-out", {
          staticClass: "mb-4",
          attrs: {
            "truck": _vm.selectedTruck,
            "disabled": !_vm.hasMaintainPermission
          }
        }), _c("net-produce-weight", {
          attrs: {
            "crop": _vm.selectedCrop,
            "disabled": !_vm.hasMaintainPermission
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("a-divider")], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("a-row", {
          attrs: {
            "justify": "end",
            "type": "flex"
          }
        }, [!_vm.create ? [_vm.hasMaintainPermission && _vm.rejectableStatus.includes(_vm.intake.status) ? _c("a-button", {
          staticClass: "mr-2",
          attrs: {
            "type": "danger",
            "ghost": ""
          },
          on: {
            "click": function($event) {
              _vm.rejectVisible = true;
            }
          }
        }, [_vm._v(" Reject Intake ")]) : _vm._e(), _vm.hasMaintainPermission && _vm.cancelableStatus.includes(_vm.intake.status) ? _c("a-button", {
          staticClass: "mr-2",
          attrs: {
            "type": "danger",
            "ghost": ""
          },
          on: {
            "click": function($event) {
              _vm.cancelVisible = true;
            }
          }
        }, [_vm._v(" Cancel Intake ")]) : _vm._e()] : _vm._e(), _vm.hasMaintainPermission && _vm.intake.status != _vm.INTAKE_STATUS_INCOMPLETE ? [_c("a-button", {
          staticClass: "mr-2",
          attrs: {
            "loading": _vm.isLoading.SampleIdentifier,
            "type": "primary",
            "ghost": ""
          },
          on: {
            "click": function($event) {
              return _vm.onReportButtonsClick("SampleIdentifier");
            }
          }
        }, [_vm._v(" Sample Identifier ")]), _c("a-button", {
          staticClass: "mr-2",
          attrs: {
            "loading": _vm.isLoading.IntakeDocket,
            "type": "primary",
            "ghost": ""
          },
          on: {
            "click": function($event) {
              return _vm.onReportButtonsClick("IntakeDocket");
            }
          }
        }, [_vm._v(" Intake Docket ")])] : _vm._e(), _vm.hasMaintainPermission ? _c("a-button", {
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v(" Cancel ")]) : _vm._e(), _vm.hasMaintainPermission ? _c("a-button", {
          staticClass: "ml-2",
          attrs: {
            "loading": _vm.isSubmitting,
            "type": "primary"
          },
          on: {
            "click": function($event) {
              return handleSubmit(_vm.submit);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.create ? "Save" : "Update") + " ")]) : _vm._e()], 2)], 1)], 1)];
      }
    }])
  })], 1), _c("reject-intake", {
    attrs: {
      "visible": _vm.rejectVisible
    },
    on: {
      "update:visible": function($event) {
        _vm.rejectVisible = $event;
      }
    }
  }), _c("cancel-intake", {
    attrs: {
      "visible": _vm.cancelVisible
    },
    on: {
      "update:visible": function($event) {
        _vm.cancelVisible = $event;
      }
    }
  })], 1);
};
var staticRenderFns$1 = [];
var CreateEditIntake_vue_vue_type_style_index_0_scoped_true_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "CreateEditIntake",
  inject: ["resourceProps", "can"],
  components: {
    CancelIntake,
    GondolaLogAndGrower,
    IntakeCode,
    IntakeInformation,
    RejectIntake,
    NetProduceWeight,
    WeightInBinsComment,
    WeightOut
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    },
    create: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const [intakeProps, , truckProps, intakeGrowerProps] = this.resourceProps;
    intakeGrowerProps.crud.clearEntity("editForm");
    return {
      INTAKE_STATUS_CANCELLED,
      INTAKE_STATUS_INCOMPLETE,
      INTAKE_STATUS_REJECTED,
      cancelableStatus: [INTAKE_STATUS_INCOMPLETE],
      cancelVisible: false,
      intakeGrowerProps,
      intakeProps,
      isInited: false,
      isSubmitting: false,
      menuItems: [],
      rejectableStatus: [
        INTAKE_STATUS_AVAILABLE_FOR_UPLOAD,
        INTAKE_STATUS_ENTERED,
        INTAKE_STATUS_INCOMPLETE,
        INTAKE_STATUS_SAMPLE_RECORDED
      ],
      rejectVisible: false,
      selectedCrop: {},
      selectedTruck: {},
      truckProps,
      isLoading: {
        SampleIdentifier: false,
        IntakeDocket: false
      }
    };
  },
  computed: {
    crops() {
      return this.$store.getters["grower.common.crops/list"];
    },
    intake() {
      return this.intakeProps.crud.getEntity() || {};
    },
    statusColor() {
      return INTAKE_STATUS_COLORS[this.intake.status];
    },
    statusText() {
      return INTAKE_STATUS_TEXTS[this.intake.status];
    },
    trucks() {
      return this.truckProps.crud.getList();
    },
    hasMaintainPermission() {
      return this.can(MAINTAIN_PERMISSION);
    }
  },
  watch: {
    intake(newVal) {
      const { cropId, truckId } = newVal;
      this.selectedCrop = this.crops.find((crop) => crop.id == cropId) || {};
      this.selectedTruck = this.trucks.find((truck) => truck.id == truckId) || {};
    },
    crops(newVal) {
      if (this._.isEmpty(this.intake))
        return;
      const { cropId } = this.intake;
      this.selectedCrop = newVal.find((crop) => crop.id == cropId) || {};
    },
    trucks(newVal) {
      if (this._.isEmpty(this.intake))
        return;
      const { truckId } = this.intake;
      this.selectedTruck = newVal.find((truck) => truck.id == truckId) || {};
    }
  },
  async created() {
    const { apiUrl: apiUrl2 } = this.truckProps;
    await this.truckProps.crud.fetchList(`${apiUrl2}/grower/common/trucks?truckTypeId=${TRUCK_TYPE_INTAKE}`, void 0, false);
    await this.intakeProps.crud.fetchEntity();
    this.generateBreadcrumbItems();
    this.isInited = true;
  },
  methods: {
    cancel() {
      this.$router.push(this.intakeProps.redirectRoute);
    },
    generateBreadcrumbItems() {
      const menuItems = [
        {
          key: "intakeprocessing",
          title: "Intake Processing",
          path: ""
        },
        {
          key: "intakelist",
          title: "Intake List",
          path: "/watties-grower/intakes"
        }
      ];
      const { id } = this.intake;
      if (this.create) {
        menuItems.push({
          key: "newintake",
          title: "New Intake",
          path: `/watties-grower/intakes/${id}?create=1`
        });
      } else {
        menuItems.push({
          key: "editintake",
          title: `Intake - ${this.intake.intakeNumber}`,
          path: `/watties-grower/intakes/${id}`
        });
      }
      this.menuItems = menuItems;
    },
    async submit() {
      this.isSubmitting = true;
      const { id } = this.intake;
      const { apiUrl: apiUrl2 } = this.intakeGrowerProps;
      try {
        await this.intakeProps.crud.submitEntity("replace", `${apiUrl2}/grower/intakes/${id}`);
        await this.intakeGrowerProps.crud.submitEntity("replace", `${apiUrl2}/grower/intakes/${id}/growers`);
      } finally {
        this.isSubmitting = false;
        this.intakeProps.crud.fetchEntity();
      }
    },
    onBackClick() {
      this.$router.push(this.intakeProps.redirectRoute);
    },
    async onReportButtonsClick(type) {
      const { intakeNumber } = this.intake;
      const data = {
        report: type,
        reportType: "grower",
        reportParams: { IntakeNumber: intakeNumber }
      };
      this.axios.post(`${apiUrl}/identities/reports`, data).then((res) => {
        if (!res || !res.data || !res.data.reportViewerUri) {
          this.$notification["error"]({
            message: "Report not found!"
          });
        } else {
          window.open(res.data.reportViewerUri, "_blank");
        }
      }, (err) => {
        this.$notification["error"]({
          message: err.message || "Errors!"
        });
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "7f33d272", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateEditIntake = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", {
    attrs: {
      "name": "grower.common.crops",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.ware-houses",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.gondolas",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.paddock",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": ["grower.intakes", "grower.intakes.status", "grower.common.trucks", "grower.intakes.growers", "grower.common.growers-contract", "grower.common.contracts-by-grower-pricingcrop", "grower.common.paddock-contract", "grower.common.grade-for-intake", "grower.intakes.gondola-logs", "grower.intakes.active-gondola-logs", "grower.common.paddock-crop", "grower.common.tubs-truck", "grower.intakes.gross-weight", "grower.intakes.intake-bins", "grower.common.container-crop", "grower.intakes.weight-out", "grower.intakes.bunkers", "grower.intakes.admin"],
      "api-url": _vm.apiUrl,
      "redirect-route": ["/watties-grower/intakes"],
      "page": _vm.page
    }
  }, [_c("create-edit-intake", {
    attrs: {
      "id": _vm.id,
      "create": _vm.create
    }
  })], 1)], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  components: {
    CreateEditIntake
  },
  props: {
    id: {
      type: [Number, String],
      default: null
    },
    create: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      apiUrl: "#{VUE_APP_API_URL}#",
      page: GROWERS_INTAKELIST
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
